@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@font-face {
  font-family: 'BigNoodleTitling';
  src: url('/public/fonts/bignoodletitling/big_noodle_titling.ttf') format('truetype');
}

@font-face {
  font-family: 'BigNoodle';
  src: url('/public/fonts/BigNoodle-ColorFont.otf') format('opentype');
  font-weight: "Open Font";
}

@font-face {
  font-family: 'LuckiestGuyBlue';
  src: url('/public/fonts/LuckiestGuy-BlueFont.otf') format('opentype');
}

@font-face {
  font-family: 'LuckiestGuyOrange';
  src: url('/public/fonts/LuckiestGuy-OrangeFont.otf') format('opentype');
}

/* React Transition Group Animation */
.fade-enter {
  transform: translateX(-50%);
}
.fade-enter-active {
  transform: translateX(100%);
  transition: transform 1000ms ease-in;
}
.fade-mask-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background-image: linear-gradient(to right, transparent, black 50%);
  z-index: 2;
  pointer-events: none;
}


body:hover {
  cursor: url('/public/assets/cursors/ghost_normal.png') 16 8, auto !important;
}

body:active {
  cursor: url('/public/assets/cursors/ghost_click.png') 16 8, auto !important;
}

a:hover, a:focus, button:hover, button:focus, input:hover, input:focus, label:hover, label:focus {
  cursor: url('/public/assets/cursors/ghost_normal.png') 16 8, auto !important;
}

a:active, button:active, input:active, label:active {
  cursor: url('/public/assets/cursors/ghost_click.png') 16 8, auto !important;
}

input[type="checkbox"]:hover, input[type="checkbox"]:focus {
  cursor: url('/public/assets/cursors/ghost_normal.png') 16 8, auto !important;
}

input[type="checkbox"]:active {
  cursor: url('/public/assets/cursors/ghost_click.png') 16 8, auto !important;
}

.gradient-overlay::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), transparent 35%, transparent 65%, rgba(0, 0, 0, 0.75));
  z-index: 35;
  pointer-events: none;
}

.musicicon {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 15px;
  height: 20px;
  margin-bottom: .25rem;
}

@keyframes bounce {
  10% {
    transform: scaleY(0.3); /* start by scaling to 30% */
  }

  30% {
    transform: scaleY(1); /* scale up to 100% */
  }

  60% {
    transform: scaleY(0.5); /* scale down to 50% */
  }

  80% {
    transform: scaleY(0.75); /* scale up to 75% */
  }

  100% {
    transform: scaleY(0.6); /* scale down to 60% */
  }
}

.musicicon span {
  width: 3px;
  height: 100%;
  background-color: #FFAA00;
  border-radius: 3px;
  transform-origin: bottom;
  transition: all 1s ease;
}

.playingicon span {
  animation: bounce 2.2s ease infinite alternate;
  transform: scaleY(1);
}

.playingicon span {
  &:nth-of-type(2) {
    animation-delay: -2.2s; /* Start at the end of animation */
  }

  &:nth-of-type(3) {
    animation-delay: -3.7s; /* Start mid-way of return of animation */
  }
}

.pausedicon span {
  background-color: white;
  transform: scaleY(0.05);
}