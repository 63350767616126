.carousel-container {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
}

.carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: background-color 0.3s linear;
}

.carousel-card:hover{
    border: 8px solid #FFEBE4;
    background-color: #FFAA00;
}

.carousel-card > img {
    border-radius: 10px;
}

.carousel-track {
    display: flex;
    position: absolute;
    right: 0;
    justify-content: center;
    align-items: center;
    /* width: 200%; */
    height: fit-content;
    animation: slide 20s linear infinite;
}

.carousel-track:hover{
    animation-play-state: paused;
}

@keyframes slide {
    0% {
      right: 0;
    }
    100% {
      right: -100%;
    }
}

@keyframes infinite-rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
/* Apply the animation to the element */
.rotate-infinite {
    animation: infinite-rotate 10s linear infinite;
}

.bg-text-stroke {
  -webkit-text-stroke: 2px #00153D;
}

@keyframes floating {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust the distance to float up */
  }
}

.floating-element {
  animation: floating 3s ease-in-out infinite;
}

.text-stroke-blue {
  -webkit-text-stroke: 3px #001A4C;
  paint-order: stroke fill;
}

.slick-list {margin: 0 -5px;}
.slick-slide>div {padding: 0 5px;}